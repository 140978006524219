import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import Success from '@components/Success/Success';

const success = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('id');

  return (
    <Layout location={location} map itinerari>
      <Seo
        title="Pagamento E-Bike avvenuto con successo! - Tiziano Bieller Sport"
        lang="it"
      />
      <Success sessionId={sessionId} />
    </Layout>
  );
};

export default success;
