import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { retrieveCheckout } from '@api';
import regolamento from '@static/pdf/regolamento-noleggio-ebike.pdf';

import * as styles from './Success.module.css';

const Success = ({ sessionId }) => {
  const [checkoutSession, handleCheckoutSession] = useState(null);

  const getSession = async id => {
    const checkout = await retrieveCheckout(id);
    if (checkout && checkout.data) {
      handleCheckoutSession(checkout.data.session);
    }
  };

  useEffect(() => {
    getSession(sessionId);
  }, [sessionId]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Grazie mille{' '}
        {checkoutSession ? checkoutSession.customer_details.name : ''}!
      </h1>
      <div className={styles.subtitle}>
        Il tuo pagamento è andato a buon fine!
        <br />
        Riceverai a breve una email con tutti i dettagli della tua prenotazione,
        il{' '}
        <a href={regolamento} target="_blank" rel="noreferrer">
          regolamento di noleggio
        </a>{' '}
        e le modalità di ritiro delle E-Bike.
      </div>
      <div className={styles.third}>
        Per qualsiasi informazione contattaci telefonicamente al{' '}
        <a href="tel:3394329988">339.4329988</a> o via email{' '}
        <a href="mailto:info@tizianobiellersport.com">
          info@tizianobiellersport.com
        </a>
      </div>
      <div className={styles.fourth}>Tiziano Bieller Sport</div>
    </div>
  );
};

Success.propTypes = {
  sessionId: PropTypes.string,
};

export default Success;
